<template>
  <div class="mt-4  px-5">

    <h2>Heute</h2>

    <v-data-table
        v-if="show_SL"
        :headers="headers"
        :items="comp_TodaySL"
        class="elevation-1 my-2"
        ref="TodaySL"
        no-data-text="Kein Schiessleiter eingebucht"
        hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar
            flat
            color="warning"
        ><h3>Schiessleiter</h3>

          <v-spacer></v-spacer>
          <v-btn icon
                 @click="getTodaySL">
            <v-icon>mdi-reload</v-icon>
          </v-btn>

        </v-toolbar>
      </template>
    </v-data-table>


    <v-data-table
        v-if="show_SA"
        :headers="headers_SA"
        :items="comp_TodaySA"
        class="elevation-1 my-2"
        ref="TodaySA"
        no-data-text="Kein Standaufischt eingebucht"
        hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar
            flat
            color="info"
        ><h3>Standaufsicht</h3>
          <v-spacer></v-spacer>
          <v-btn icon
                 @click="getTodaySA">
            <v-icon>mdi-reload</v-icon>
          </v-btn>

        </v-toolbar>
      </template>
    </v-data-table>

    <v-data-table
        v-if="show_user"
        :headers="headers"
        :items="comp_TodayMember"
        sort-by="KurzBez"
        group-by="KurzBez"
        class="elevation-1 my-2"
        ref="TodayMember"
        no-data-text="Kein Schütze eingebucht"
    >
      <template v-slot:top>
        <v-toolbar
            flat
            color="success"
        ><h3>Schützen</h3>
          <v-spacer></v-spacer>
          <v-btn icon
                 @click="getTodayMember">
            <v-icon>mdi-reload</v-icon>
          </v-btn>

        </v-toolbar>
      </template>

    </v-data-table>

<!--    <range_safty_popup_mqtt/>-->

  </div>
</template>

<script>
import client, {initializeAblyClient} from "@/plugins/ably";
// import range_safty_popup_mqtt from "@/views/component/range_safty_popup_mqtt.vue";

export default {
  name: 'today',
  data: () => ({
    polling: null,
    dialog: false,
    dialogDelete: false,
    loading: false,
    show_SL: true,
    show_SA: true,
    show_user: true,
    channel: null,
    channel2: null,
    headers: [
      {
        align: 'Name',
        sortable: false,
      },
      {text: 'Name', value: 'name'},
      {text: 'Vorname', value: 'vorname'},
      {text: 'Stand', value: 'KurzBez'},
      {text: 'Bahn', value: 'bahn'},
      {text: 'Funktion', value: 'Aufsicht'},
      {text: 'Begin', value: 'BeginFormat'}
    ],
    headers_SA: [
      {
        align: 'Name',
        sortable: false,
      },
      {text: 'Name', value: 'name'},
      {text: 'Vorname', value: 'vorname'},
      {text: 'Stand', value: 'KurzBez'},
      {text: 'Begin', value: 'BeginFormat'}
    ],

  }),

  computed: {
    comp_TodayMember() {
      return this.$store.state.ClubApp.TodayMember;
    },
    comp_TodaySA() {
      return this.$store.state.ClubApp.TodaySA;
    },
    comp_TodaySL() {
      return this.$store.state.ClubApp.TodaySL;
    },
    isClubLoggedIn() {
      return this.$store.getters["ClubAuth/isClubLoggedIn"];
    },
    isClubID() {
      return this.$store.getters["ClubAuth/isClubID"];
    },

  },
  created() {
    const params = this.$route.params;
    if (Object.keys(params).length === 0) {
      this.$store.commit('GeneralApp/SET_SHOW_NAVIGATION', true)
      this.start_websocket();

    } else {
      this.loginwithToken();
    }
  },

  beforeMount() {
    this.getTodayMember();
    this.getTodaySA();
    this.getTodaySL();
  },
  beforeDestroy() {
    this.channel.unsubscribe();
    this.channel2.unsubscribe();
  },

  methods: {
// ...
    async start_websocket() {
      await initializeAblyClient();

      // eslint-disable-next-line no-unused-vars
      await client.connection.on('connecting', (stateChange) => {
      });

      await client.connection.on((stateChange) => {
        // console.log('New connection state is ' + stateChange.current);
        if (stateChange.current === 'disconnected') {
          this.start_websocket();
        }
      });

      this.channel = await client.channels.get('private:clubs.' + this.isClubID + '.ClubTodayChannel');
      this.channel2 = await client.channels.get('clubs/' + this.isClubID + '/range_safty');

      await this.channel.subscribe((message) => {
        this.$store.commit('ClubApp/get_TodaySA', message.data.message.TodaySA)
        this.$store.commit('ClubApp/get_TodaySL', message.data.message.TodaySL)
      });

      await this.channel2.subscribe((msg) => {
        // msg.data enthält Ihre ArrayBuffer-Daten
        // verwandeln Sie die binären Daten in eine String-Repräsentation
        const jsonString = new TextDecoder().decode(msg.data);

        try {
          // versuchen Sie, den JSON-String zu einem JavaScript-Objekt zu parsen
          const data = JSON.parse(jsonString);
          console.log(data);  // sollte Ihr ursprüngliches Objekt anzeigen
        } catch(e) {
          console.error("Konnte den Nachrichteninhalt nicht als JSON parsen", e);
        }
      });

    },

    // startPolling() {
    //   if (this.isClubLoggedIn) {
    //     this.getTodayMember();
    //     this.getTodaySA();
    //     this.getTodaySL();
    //   }
    //   this.polling = setInterval(() => {
    //     this.getTodayMember();
    //     this.getTodaySA();
    //     this.getTodaySL();
    //   }, 15000);
    // },
    getTodayMember() {
      if (this.show_user) {
        this.$store.dispatch('ClubApp/getTodayMember')
      }
    },
    getTodaySA() {
      if (this.show_SA) {
        this.$store.dispatch('ClubApp/getTodaySA')
      }
    },
    getTodaySL() {
      if (this.show_SL) {
        this.$store.dispatch('ClubApp/getTodaySL')
      }
    },
    async loginwithToken() {
      const params = this.$route.params;
      const token = params.token;
      const clubid = params.clubid;

      const data = {
        clubid: clubid,
        token: token
      };

      this.show_user = false;
      this.show_SL = false;
      this.show_SA = true;

      await this.$store.dispatch('ClubAuth/login', data)
          .then(() =>
              this.$store.commit('GeneralApp/SET_SHOW_NAVIGATION', false)
          )
          .catch(err => console.log(err))
          .finally(this.start_websocket)
          .finally(this.getTodaySA)
          .finally(this.getTodayMember)
          .finally(this.getTodaySL)
    }


  },
}
</script>

<style scoped>

</style>

